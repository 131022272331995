.toastContainer {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 9999;
}

.toast {
  background-color: var(--red-1);
  color: #333;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateX(-100%);
  transition: transform 0.24s ease-out;
}

.toast.visible {
  transform: translateX(0);
}

.toastText {
  flex: 1;
}

.iconContainer {
  margin-right: 3%;
  font-size: 18px;
  line-height: 0;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 26px;
  display: flex;
  align-items: center;
  svg {
    path {
      stroke: #4b4b4b;
    }
  }
}

.container {
  display: flex;
  width: 100vw;
  height: 100vh;

  .main {
    display: flex;
    flex: 1;
    flex-direction: column;

    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: scroll;
      scroll-behavior: smooth;
    }
  }
}

.breadcrumbsContainer {
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  .breadcrumbs {
    display: flex;
    align-items: center;
    gap: var(--spacing-lg, 12px);

    .item {
      color: var(--text-tertiary-600);
      font: var(--sm-medium);
      text-decoration: unset;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        cursor: text;
        text-decoration: unset;
        color: var(--purple-3);

        svg path {
          stroke: var(--purple-3);
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

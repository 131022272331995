.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(12, 17, 29, 0.9);
  backdrop-filter: blur(8px);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overscroll-behavior: contain;
}

.container {
  display: flex;

  .badge {
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 18px;
    padding: 2px 8px;
    border: 1px solid;
    border-radius: 9999px;
    align-self: flex-start;
    flex-shrink: 0;
    flex-grow: 0;

    .content {
      overflow: hidden;
      font: var(--xs-medium);
      white-space: nowrap;
      text-align: center;
      text-transform: capitalize;
      text-overflow: ellipsis;

      &.largeText {
        font: var(--sm-medium);
      }
    }

    &.clickable {
      cursor: pointer;
    }

    &.green {
      color: var(--green-3);
      background-color: var(--green-1);
      border-color: var(--green-2);
    }

    &.orange {
      color: var(--orange-3);
      background-color: var(--orange-1);
      border-color: var(--orange-2);
    }

    &.red {
      color: var(--red-3);
      background-color: var(--red-1);
      border-color: var(--red-2);
    }

    &.purple {
      color: var(--purple-3);
      background-color: var(--purple-1);
      border-color: var(--purple-2);
    }

    &.blue {
      color: var(--blue-3);
      background-color: var(--blue-1);
      border-color: var(--blue-2);
    }

    &.indigo {
      color: var(--indigo-3);
      background-color: var(--indigo-1);
      border-color: var(--indigo-2);
    }

    &.pink {
      color: var(--pink-3);
      background-color: var(--pink-1);
      border-color: var(--pink-2);
    }

    &.gray {
      color: var(--gray-8);
      background-color: var(--gray-0);
      border-color: var(--gray-2);
    }
  }
  .badgeAvatar {
    position: relative;
    top: 3px;
    width: 16px;
    height: 16px;
    margin: 0 4px 0 0;
    border-radius: 50%;
  }

  a {
    color: var(--text-tertiary-600);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.footer {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 52px;
  padding: 0 12px;
  color: var(--text-quaternary-500, #667085);
  font: var(--sm-semibold);
  cursor: default;
}

.arrowBoxEsc,
.arrowBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  border: 1px solid var(--border-secondary, #eaecf0);
  border-radius: var(--radius-md, 8px);
}

.arrowBox {
  width: 28px;
}

.arrowBoxEsc {
  padding: 0 8px;
}

.commandMenuWrapper {
  position: fixed;
  top: 100px;
  left: 0;
  z-index: 101;
  pointer-events: none;

  .commandMenuInnerWrapper {
    display: flex;
    justify-content: center;
    width: 100vw;
    pointer-events: none;
  }

  * {
    pointer-events: all;
  }

  [cmdk-root] {
    width: 100%;
    max-width: 640px;
    padding: 8px 0 0;
    overflow: hidden;
    background: #fff;
    border-radius: 12px;
    outline: none;
    transition: transform 1000ms ease;
  }

  [cmdk-empty] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    color: var(--text-primary-900, #101828);
    font: var(--sm-medium);
  }

  input {
    width: 100%;
    padding: 8px;
    color: var(--text-placeholder, #667085);
    font: var(--md-regular);
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--gray6);
    border-radius: 0;
    outline: none;

    &::placeholder {
      color: var(--gray9);
    }
  }

  [cmdk-group] {
    padding-top: 8px;
    border-bottom: 1px solid var(--border-secondary, #eaecf0);
  }

  [cmdk-group-heading] {
    padding: 0 16px;
    color: var(--text-tertiary-600, #475467);
    font: var(--sm-medium);
    cursor: default;
  }

  [cmdk-item] {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    color: var(--text-primary-900, #101828);
    font: var(--sm-medium);
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 150ms ease;
    transition-property: none;
    user-select: none;
    will-change: background-color, color;
    content-visibility: auto;

    &[data-selected='true'] {
      background: var(--gray-0, #f8f9fa);
    }

    &[data-disabled='true'] {
      color: var(--gray8);
      cursor: not-allowed;
    }

    &:active {
      background: var(--gray-0, #f8f9fa);
      transition-property: background-color;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    path {
      stroke: #535c72;
    }
  }

  [cmdk-list] {
    height: min(330px, calc(var(--cmdk-list-height)));
    max-height: 400px;
    padding-top: 4px;
    overflow: auto;
    transition: 100ms ease;
    transition-property: height;
    overscroll-behavior: contain;
  }
}

@keyframes opacity-animation {
  0% {
    background-color: transparent;
    opacity: 0;
    backdrop-filter: blur(0);
  }

  100% {
    background-color: #0000007a;
    opacity: 1;
    backdrop-filter: blur(3px);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: #0000007a;
  backdrop-filter: blur(3px);
  animation: opacity-animation 300ms 1;
}

.inputWrapper {
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-bottom: 1px solid var(--border-secondary, #eaecf0);
}

.inputWrapper kbd {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  color: var(--text-tertiary-600, #475467);
  font: var(--sm-regular);
  text-align: center;
  background: var(--utility-gray-50, #f9fafb);
  border: 1px solid var(--border-secondary, #eaecf0);
  border-radius: 4px;
  cursor: default;
}

.searchIcon {
  // margin-bottom: 26px;
  width: 20px;
  height: 20px;
}

.companyOwner {
  width: 24px;
  height: 24px;
  background-size: cover;
  border-radius: 50%;
}

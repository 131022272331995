.layoutDrawer {
  position: relative;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 312px;
  height: 100vh;
  padding: 32px 16px 0;
  background-color: hsl(0deg 0% 100%);
  border-right: 1px solid var(--border-primary, #d0d5dd);
}

.innerDrawerLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.drawerNavigation {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: var(--spacing-3xl, 24px);
}

.drawerNavigation svg {
  width: 24px;
  height: 24px;
}

.listItem {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  color: var(--text-secondary, #182230);
  text-decoration: none;
  column-gap: 12px;
  border-radius: var(--radius-md, 8px);
  cursor: pointer;
}

.listItemActive {
  background-color: #f9fafb;
}

.listItem path {
  stroke: #667085;
}

.listItem:hover {
  background-color: #f9fafb;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--text-2-light);
}

.userBox {
  display: flex;
  margin-top: var(--spacing-3xl, 24px);
  margin-bottom: var(--spacing-3xl, 24px);
  padding-top: var(--spacing-3xl, 24px);
  column-gap: var(--spacing-lg, 12px);
  border-top: 1px solid var(--border-secondary, #eaecf0);
  cursor: default;
}

.userCircel {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.userDetails {
  margin-right: auto;
  color: var(--text-primary-900, #101828);
  font: var(--md-semibold);
}

.userDetails p {
  font: var(--sm-regular);
}

.logoutButton {
  width: 28px;
  height: 28px;
  background: none;
  border: none;
  cursor: pointer;
}

.kpiWrapper {
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
  transition: grid-template-rows 300ms cubic-bezier(0.45, 0, 0.55, 1);
}

.kpiWrapper div {
  min-height: 0;
  padding: 0 0 0 28px;
}
